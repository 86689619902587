import { Box, Typography, useMediaQuery, useTheme, Drawer, IconButton, Grid, CircularProgress, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ScrollCouponComponent from '../../components/ScrollCouponComponent';
import axios from 'axios';
import { API } from '../../utils/Api';
import ProductDrawer from '../../components/ProductDrawer';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import { CloseOutlined } from '@mui/icons-material';
import Images from '../../utils/Images';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddonIconComponent from '../../components/AddonIconComponent';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../store/Cart/cartSlice';

const OfferScreen = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const outlet = localStorage.getItem("selectedStoreData");
  const token = localStorage.getItem('token');
  const deliveryType = localStorage.getItem("selectedOrderType");
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
  const [clickableAdvertise, setClickableAdvertise] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [open, setopen] = useState(false);
  const [selectedAddons, setSelectedAddons] = useState({});
  const [addingToCart, setAddingToCart] = useState(false);
  const outletObject = JSON.parse(outlet);
  const outletId = outletObject?._id;
  const matchesMdUp = useMediaQuery(theme.breakpoints.up('lg'));
  const [isFavorited, setIsFavorited] = useState(false);
  const [wishlistData, setWishlistData] = useState([]);
  const cartDataRedux = useSelector(state => state?.cart?.data);
  const [cartData, setCartData] = useState([]);

  const getWishlist = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}wishlist/getUserWishlist/${outletData?._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setWishlistData(response?.data?.data?.wishList_product_Ids);
      console.log('Wishlist in initial Screen Data', response?.data?.data?.wishList_product_Ids);
    } catch (error) {
      console.log('Wishlist Screen Error', error?.response?.data);
    }
  };

  const isProductInWishlist = (productId) => {
    return wishlistData.includes(productId);
  };

  const handleAddonSelect = (addonId, addonValue) => {
    setSelectedAddons((prevSelected) => ({
      ...prevSelected,
      [addonId]: addonValue,
    }));
    // console.log('Addons', { addonId, ...addonValue });
  };

  const handleFavoriteClick = async () => {
    if (token) {
      try {
        if (isProductInWishlist(selectedProduct._id)) {
          const response = await axios.post(
            `${API.BASE_URL}wishlist/remove`, 
            { productId: selectedProduct._id },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setIsFavorited(false);
          toast.success('Product removed from favorites!!!');
          console.log("Wishlist Remove Success", response.data);
          getWishlist();
        } else {
          const response = await axios.post(
            `${API.BASE_URL}wishlist/add`, 
            { productId: selectedProduct._id },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setIsFavorited(true);
          getWishlist();
          toast.success('Product added to favorites!!!');
          console.log("Wishlist Add Success", response.data);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setopen(true);
    }
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsFavorited(isProductInWishlist(product._id));
    console.log('Product', product);
    console.log('Product', product);
    // Set default addons
    const defaultAddons = {};
    product.addons.forEach((addon) => {
        const defaultValue = addon.addonValues.find(value => value.isDefault);
        if (defaultValue) {
            defaultAddons[addon._id] = {
                ...defaultValue,
                offerPrice: defaultValue.offerPrice ?? 0,
                mrp: defaultValue.mrp ?? 0,
            };
        }
    });
    setSelectedAddons(defaultAddons);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setQuantity(1);
    setSelectedAddons({});
  };

  const handleAddToCart = async () => {
    if (token) {
      setAddingToCart(true);
      try {
        const addonsArray = Object.keys(selectedAddons).map((addonId) => ({
          _id: addonId,
          selectedValue: selectedAddons[addonId].value,
          addonValueId: selectedAddons[addonId]._id,
        }));
        const cartItem = {
          ordersType: deliveryType,
          outlet: outletId,
          cartItems: [
            {
              product: selectedProduct._id,
              quantity: quantity,
              addons: addonsArray.length > 0 ? addonsArray : undefined,
            }
          ],
        };
        const response = await axios.post(`${API.BASE_URL}cart`, cartItem, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        handleCloseModal();
        fetchUserCartData();
        setAddingToCart(false);
        toast.success('Product added to cart!!!');
        console.log(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setAddingToCart(false);
      }
    } else {
      if (matchesMdUp) {
        setopen(true);
      } else {
        navigate('/mobileNumber');
      }
    }
  };

  const fetchUserCartData = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}cart/userCart/${outletData._id}/${deliveryType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setData(response?.data));
      setCartData(cartDataRedux);
      // console.log('cartDataRedux', cartDataRedux);
      console.log('Cart Screen Data', response?.data?.data?.cartItems);
    } catch (error) {
      dispatch(setData([]));
      console.log('Cart Screen Error', error?.response?.data);
    }
  };

  const getAdvertisments = async () => {
    const url = outletData && outletData._id 
      ? `${API.BASE_URL}advertisement/user?outletId=${outletData._id}`
      : `${API.BASE_URL}advertisement/user`;
    try{
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Clickable Ads', response.data.data.advertisements.clickableAdvertisements);
      setClickableAdvertise(response?.data?.data?.advertisements?.clickableAdvertisements);
    } catch (error) {
      console.log("Error in getting Ads", error?.response);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAdvertisments();
    getWishlist();
  }, []);

  const handleIncreaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const [totalPrice, setTotalPrice] = useState(selectedProduct?.offerPrice || 0);
  const [totalMrpPrice, setTotalMrpPrice] = useState(selectedProduct?.mrp || 0);
  const [offerPercentage, setOfferPercentage] = useState(0);

  useEffect(() => {
    let calculateTotalPrice = () => {
      let price = selectedProduct?.offerPrice ?? 0;
      let mrp = selectedProduct?.mrp ?? 0;
      Object.values(selectedAddons).forEach(addon => {
        price += addon.offerPrice ?? 0;
        mrp += addon.mrp ?? 0;
      });
      setTotalPrice(price);
      setTotalMrpPrice(mrp);
    };
    calculateTotalPrice();
  }, [selectedAddons, selectedProduct]);
  
  useEffect(() => {
    if (totalMrpPrice > 0 && totalPrice < totalMrpPrice) {
      const discount = ((totalMrpPrice - totalPrice) / totalMrpPrice) * 100;
      setOfferPercentage(discount);
    } else {
      setOfferPercentage(0);
    }
  }, [totalPrice, totalMrpPrice]);

  return (
    <Box className="offerScreen">
      <Box className="title">
        <Typography variant='h6' className="titleText">Offers</Typography>
      </Box>
      {clickableAdvertise.map((ad, index) => (
        <div className='adImageBox' key={index}>
          <img loading='lazy' className="adImage" onClick={() => handleProductClick(ad?.product)} src={ad.adImagePath} alt={`Advertisement ${index}`} />
        </div>
      ))}
      <Drawer
        open={!!selectedProduct}
        onClose={handleCloseModal}
        anchor="bottom"
      >
        <Box className="productDrawer">
          <IconButton 
            onClick={handleCloseModal}
            sx={{ position: 'absolute', top: -20, right: 8, zIndex: 1, backgroundColor: '#fff', height: '16px', width: '16px' }}
          >
            <CloseOutlined sx={{ fontSize: '12px' }} />
          </IconButton>
          <Grid container>
            <Grid item xs={5} sx={{ justifyContent: 'center'}}>
              <Box className="imageBox">
                <img className="image" src={selectedProduct?.displayImage} alt="Product" />
              </Box>
            </Grid>
            <Grid item xs={7} sx={{ paddingLeft: '2%'}}>
              <Box className="drawerVegAndFavourite">
                <Box sx={{ display: 'flex' }}>
                  <Typography className="productRating" sx={{ textAlign: 'right'}}><StarRoundedIcon className="star"/>{selectedProduct?.productRatings?.averageProductRatings ? selectedProduct.productRatings.averageProductRatings.toFixed(1) : 4.2}</Typography>
                  <>
                    {selectedProduct?.foodType === 'VEG/VEGAN' ? (
                      <Stack direction={'row'}>
                        <img className='vegNonveg' src={Images.veg} alt='veg' />
                        <img className='vegNonveg' src={Images.vegan} alt='vegan' />
                      </Stack>
                    ) : (
                      <img className='vegNonveg' src={selectedProduct?.foodType === 'VEG' ? Images.veg : (selectedProduct?.foodType === 'VEGAN' ? Images.vegan : Images.nonVeg)} alt={selectedProduct?.foodType === 'VEG' ? 'veg' : (selectedProduct?.foodType === 'VEGAN' ? 'vegan' : 'non-veg')} />
                    )}
                  </>
                </Box>
                {isFavorited ? ( <FavoriteRoundedIcon className="favouriteMarked" onClick={handleFavoriteClick} /> ) : ( <FavoriteBorderRoundedIcon className="favouriteMarked" onClick={handleFavoriteClick} /> )}
              </Box>
              <Box className="productDetails">
                {/* <Typography className="productTag">{selectedProduct.productTag[0].tagName}</Typography> */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography className="productName" sx={{ textAlign: 'left'}}>{selectedProduct?.name}</Typography>
                </Box>
                <Typography className="productInfo" sx={{ paddingBottom: '0px' }}>{selectedProduct?.calories} cal {selectedProduct?.weight}<span style={{ textTransform: 'capitalize' }}>{selectedProduct?.weightUnit}</span> </Typography>
                <Typography className="productInfo">{selectedProduct?.description}</Typography>
              </Box> 
            </Grid>
          </Grid>
          {selectedProduct && (
            <>
              {selectedProduct.hasAddon && selectedProduct.addons && selectedProduct.addons.length > 0 && (
                <Box className="addonsBox">
                  {selectedProduct.addons.map((addon) => (
                    <Grid container key={addon._id} sx={{ borderBottom: '1px solid #B3B3B3', padding: '10px 0 7px 0' }}>
                      <Grid item xs={3}>
                        <Typography className="addonTitle">{addon.addonTitle}</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Grid container justifyContent="flex-end">
                          {addon.addonValues.map((addonValue) => {
                            const isSelected = selectedAddons[addon._id]?._id === addonValue._id || (!selectedAddons[addon._id] && addonValue.isDefault);
                            const icon = addonValue.iconInfo ? <AddonIconComponent iconInfo={addonValue.iconInfo} selected={isSelected} /> : null;
                            return (
                              <Grid item xs={4} key={addonValue._id} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Box 
                                  className="addonItem" 
                                  onClick={() => handleAddonSelect(addon._id, { ...addonValue, offerPrice: addonValue.offerPrice ?? 0, mrp: addonValue.mrp ?? 0 })}  
                                  sx={{ display: 'flex', flexDirection: 'column' }}
                                >
                                  <Box sx={{ flex: '1 1 auto' }} />
                                    {icon ? (
                                      <Box className="addonIcon" sx={{ margin: 0, padding: 0 }}> {icon} </Box>
                                    ) : (
                                      <Box className={`addonSelect ${isSelected ? 'selected' : ''}`} sx={{ alignSelf: 'center' }}> {addonValue.value ?? 0} </Box>
                                    )}
                                    <Typography className="addonValue" sx={{ alignSelf: 'center' }}> +₹{addonValue.offerPrice ?? 0} </Typography>
                                    {/* <Typography className="addonOutOfStock">Out Of Stock</Typography> */}
                                  </Box>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              )}
            </>
          )}
          <Box className="priceAndButton">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography className="productPrice">₹{totalPrice} <span className='productMrp'> ₹{totalMrpPrice}</span></Typography>
              <Typography className="productOff">{offerPercentage?.toFixed(0)}% OFF</Typography>
            </Box>
            <Box className="addRemoveItem">
              <RemoveCircleIcon className="text" onClick={handleDecreaseQuantity} />
              <Typography sx={{ color: '#000' }} className="text">{quantity}</Typography>
              <AddCircleIcon className="text" onClick={handleIncreaseQuantity} />
            </Box>
          </Box>
          {addingToCart ? (
            <Box className="button"><CircularProgress size={18} sx={{ color: '#fff' }} /> Adding to cart</Box>
          ) : (
            <Box className={selectedProduct?.isExhausted ? "exhaustedButton" : "button"} onClick={!selectedProduct?.isExhausted ? () => handleAddToCart() : null}>{selectedProduct?.isExhausted ? "Out Of Stock" : "ADD"}</Box>
          )}
        </Box>
      </Drawer>
    </Box>
  )
}

export default OfferScreen;
