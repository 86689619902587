import React, { useState, useEffect, useRef} from 'react';
import { Box, Grid, Stack, Typography, Dialog, Drawer, Button, Hidden, useTheme, useMediaQuery, TextField, Modal, InputAdornment, IconButton, CircularProgress } from '@mui/material';
import Images from '../../utils/Images';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts, fetchCategories } from '../../store/Products/productsSlice';
import axios from 'axios';
import { API } from '../../utils/Api';
import OrderAgain from '../../components/OrderAgain';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { SearchBar, useSearch } from '../../components/SearchBar';
import StoreHeader from '../../components/StoreHeader';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { setData } from '../../store/Cart/cartSlice';
import FavouriteProducts from '../../components/FavouriteProducts';
import PrimaryButton from '../../components/PrimaryButton';
import * as Yup from 'yup';
import { ArrowBack, ArrowDownward, CloseOutlined, KeyboardArrowDown } from '@mui/icons-material';
import { setUser } from '../../store/Profile/UserSlice';
import IconComponent from '../../components/AddonIconComponent';
import AddonIconComponent from '../../components/AddonIconComponent';
import ArrivingNowOrders from '../../components/ArrivingNowOrders';
import ProductListingSkeleton from '../../components/SkeletonComponents/ProductListingSkeleton';

const ProductsListing = () => {
  const [showBox, setShowBox] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const dispatch = useDispatch();
  // const products = useSelector((state) => state.products.products);
  // const categories = useSelector((state) => state.products.categories);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const status = useSelector((state) => state.products.status);
  const error = useSelector((state) => state.products.error);
  const [activeCategory, setActiveCategory] = useState(categories[0]?._id);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const outlet = localStorage.getItem("selectedStoreData");
  const deliveryType = localStorage.getItem("selectedOrderType");
  const token = localStorage.getItem("token");
  const outletObject = JSON.parse(outlet);
  const outletId = outletObject?._id;
  const [isFavorited, setIsFavorited] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [cartData, setCartData] = useState([]);
  // const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  // const data = useSelector(state => state.cart.data);
  // const [data, setData] = useState(null);
  const { searchText } = useSearch();
  const [wishlistData, setWishlistData] = useState([]);
  // const cartDataRedux = useSelector((state) => state.setData);
  const cartDataRedux = useSelector(state => state?.cart?.data);
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const theme = useTheme();
  const matchesMdUp = useMediaQuery(theme.breakpoints.up('lg'));
  const [openAccount, setopenAccount] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [number, setNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [countdown, setCountdown] = useState(59);
  const [errors, setErrors] = useState({});
  const [showOtpField, setShowOtpField] = useState(false);
  const [isGetOtpButtonClicked, setIsGetOtpButtonClicked] = useState(false);
  const [changeNumberClicked, setChangeNumberClicked] = useState(false); 
  const [authType, setAuthType] = useState('');
  const [open, setopen] = useState(false);
  const [selectedAddons, setSelectedAddons] = useState({});
  const [addingToCart, setAddingToCart] = useState(false);
      
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchCategories()).then((action) => {
        const fetchedCategories = action.payload;
        const initialCategoryId = fetchedCategories.length > 0 ? fetchedCategories[0]._id : null;
        setActiveCategory(initialCategoryId);
        dispatch(fetchProducts(initialCategoryId));
      });
    }
  }, [status, dispatch]);

  const handleCategoryClick = (categoryId, index) => {
    setActiveCategory(categoryId);
    dispatch(fetchProducts(categoryId));
    console.log("Products after dispatching fetchProducts:", products);
    scrollToIndex(index);
  };

  useEffect(() => {
    fetchUserCartData();
    const shimmerTimeout = setTimeout(() => {
      setShowBox(true);
    }, 3000);
    return () => clearTimeout(shimmerTimeout);
  }, []);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsFavorited(isProductInWishlist(product._id));
    console.log('Product', product);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setQuantity(1);
    setSelectedAddons({});
  };
      
  const handleIncreaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleAddonSelect = (addonId, addonValue) => {
    setSelectedAddons((prevSelected) => ({
      ...prevSelected,
      [addonId]: addonValue,
    }));
    // console.log('Addons', { addonId, ...addonValue });
  };

  useEffect(() => {
    if (selectedProduct?.hasAddon && selectedProduct?.addons && selectedProduct?.addons?.length > 0) {
      const defaultAddons = {};
      selectedProduct?.addons?.forEach((addon) => {
        const defaultAddonValue = addon?.addonValues?.find((value) => value?.isDefault);
        if (defaultAddonValue) {
          defaultAddons[addon._id] = defaultAddonValue;
        }
      });
      setSelectedAddons(defaultAddons);
    }
  }, [selectedProduct]);

  const handleAddToCart = async () => {
    if (token) {
      setAddingToCart(true);
      try {
        const addonsArray = Object.keys(selectedAddons).map((addonId) => ({
          _id: addonId,
          selectedValue: selectedAddons[addonId].value,
          addonValueId: selectedAddons[addonId]._id,
        }));
        const cartItem = {
          ordersType: deliveryType,
          outlet: outletId,
          cartItems: [
            {
              product: selectedProduct._id,
              quantity: quantity,
              addons: addonsArray.length > 0 ? addonsArray : undefined,
            }
          ],
        };
        const response = await axios.post(`${API.BASE_URL}cart`, cartItem, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        handleCloseModal();
        fetchUserCartData();
        setAddingToCart(false);
        toast.success('Product added to cart!!!');
        console.log(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setAddingToCart(false);
      }
    } else {
      if (matchesMdUp) {
        setopen(true);
      } else {
        navigate('/mobileNumber');
      }
    }
  };

  const fetchUserCartData = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}cart/userCart/${outletData._id}/${deliveryType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setData(response?.data));
      setCartData(cartDataRedux);
      // console.log('cartDataRedux', cartDataRedux);
      console.log('Cart Screen Data', response?.data?.data?.cartItems);
    } catch (error) {
      dispatch(setData([]));
      console.log('Cart Screen Error', error?.response?.data);
    }
  };

  const getCategoriesList = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}masters/category/getCategoryWithProducts/${outletData._id}`,{
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          search: searchText,
        }
      }); 
      setCategories(response.data.data.categories);
      // setProducts(response.data.data.products);
      console.log('Categories fetched successfully in the screen:', categories);
    } catch (error) {
      console.error('Error:', error);
    }
  };  

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        getCategoriesList();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let timeoutId;
    const checkDataAvailability = () => {
      if (outletData) {
        setIsDataAvailable(true);
        clearTimeout(timeoutId);
        getCategoriesList();
      } else {
        timeoutId = setTimeout(checkDataAvailability, 500);
        setOutletData(JSON.parse(localStorage.getItem('selectedStoreData')));
        console.log('sdfasdf');
      }
    };
    checkDataAvailability();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [outletData]);

  const getWishlist = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}wishlist/getUserWishlist/${outletData._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setWishlistData(response?.data.data.wishList_product_Ids);
      console.log('Wishlist in initial Screen Data', response?.data.data.wishList_product_Ids);
    } catch (error) {
      console.log('Wishlist Screen Error', error?.response?.data);
    }
  };

  const isProductInWishlist = (productId) => {
    return wishlistData.includes(productId);
  };

  const handleFavoriteClick = async () => {
    if (token) {
      try {
        if (isProductInWishlist(selectedProduct._id)) {
          const response = await axios.post(
            `${API.BASE_URL}wishlist/remove`, 
            { productId: selectedProduct._id },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setIsFavorited(false);
          toast.success('Product removed from favorites!!!');
          console.log("Wishlist Remove Success", response.data);
          getCategoriesList();
          getWishlist();
        } else {
          const response = await axios.post(
            `${API.BASE_URL}wishlist/add`, 
            { productId: selectedProduct._id },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setIsFavorited(true);
          getCategoriesList();
          getWishlist();
          toast.success('Product added to favorites!!!');
          console.log("Wishlist Add Success", response.data);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setopen(true);
    }
  };

  useEffect(() => {
    // getProductsList(activeCategory);
    getCategoriesList();
  },[activeCategory, searchText]);

  useEffect(() => {
    fetchUserCartData();
    getCategoriesList();
    getWishlist();
    // getProductsList(activeCategory);
  },[]);

  const getProductQuantityInCart = (productId) => {
      let totalQuantity = 0;
      cartDataRedux?.data?.cartItems?.forEach(item => { if (item.product._id === productId) { totalQuantity += item.quantity; }});
      return totalQuantity;
  };

  const containerRef = useRef(null);
  
  const scrollToIndex = (index) => {
    const container = containerRef.current;
    const element = container.childNodes[index];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleClose = () => {
    setopen(false);
  }
  
  const handleOpenAccount = ()=> {
    setopenAccount(true);
  }

  const handleCloseAccount = () => {
    setopenAccount(false);
  }

  useEffect(() => {
    let interval;
    if (isGetOtpButtonClicked && countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [countdown, isGetOtpButtonClicked]);

  const schema = Yup.object().shape({
    number: Yup.string()
      .matches(/^[6789][0-9]{9}$/, 'Must be a 10 digit number starting with 6, 7, 8, or 9')
      .required('Mobile Number is required'),
  });

  const validateField = async (field, value) => {
    try {
      await schema.validateAt(field, { number: value });
      setErrors({ ...errors, [field]: '' });
      if (field === 'number' && value.length === 10) {
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setErrors({ ...errors, [field]: error.message });
      }
    }
  };

  const handleResendOtp = async () => {
    try {
      const response = await axios.post(`${API.BASE_URL}auth/user/send-otp`, {
        "mobile": number,
      });
      if (response.data.status === true) {
        toast.success('OTP resent successfully');
      }
    } catch (error) {
      console.error('Error:', error.response.data.data.message);
      let errorMessage = error.response.data.data.message;
      toast.error(errorMessage);
    } finally {
    }
  };

  const handleInputChange = (field, value) => {
    if (field === 'number' && !isGetOtpButtonClicked) {
      setNumber(value);
      validateField('number', value);
    } else if (field === 'otp') {
      setOtp(value);
      setErrors({ ...errors, [field]: '' });
    }
  };

  const handleButtonClick = async () => {
    const isValid = await schema.isValid({ number });
    if (!isValid) {
      setErrors({ ...errors, number: 'Mobile Number is required' });
      return;
    }
    setIsGetOtpButtonClicked(true);
    setBtnDisabled(true);
    try {
      const response = await axios.post(`${API.BASE_URL}auth/user/send-otp`, {
        "mobile": number,
      });
      if (response.data.status === true) {
        console.log('OTP sent successfully:', response.data);
        toast.success('OTP sent successfully!');
        setShowOtpField(true);
        setCountdown(59);
      }
      setAuthType(response.data.data.authType);
      setBtnDisabled(false);
    } catch (error) {
      console.error('Error:', error.response.data.data?.message);
      let errorMessage = error.response.data.data.message;
      toast.error(errorMessage);
      setBtnDisabled(false);
    }
  };

  const handleOtpButtonClick = () => {
    if (authType === 'LOGIN') {
      setBtnDisabled(true);
      axios.post(`${API.BASE_URL}auth/user/verify-otp`, {
        "mobile": number,
        "otp": otp,
        "isSignup": false
      })
      .then(response => {
        console.log('Login API Response:', response.data);
        localStorage.setItem('userNameHeader', response.data.data.user.name);
        localStorage.setItem('userProfileImage', response.data.data.user.profileImage);
        localStorage.setItem('token', response.data.data.token);
        localStorage.setItem('userDataLogin', JSON.stringify(response.data.data.user));
        dispatch(setUser(response.data.data.user));
        toast.success('Logged in successfully!');
        setBtnDisabled(false);
        handleClose();
        window.location.reload();
      })
      .catch(error => {
        console.error('Error:', error.response.data.data.message);
        let errorMessage = error.response.data.data.message;
        toast.error(errorMessage);
        setBtnDisabled(false);
      });
    }
    else {
      setBtnDisabled(true);
      axios.post(`${API.BASE_URL}auth/user/verify-otp`, {
        "mobile": number,
        "otp": otp,
        "isSignup": true
      })
      .then(response => {
        console.log('Signin API Response:', response.data);
        toast.success('OTP verified successfully!');
        setBtnDisabled(false);
        handleClose();
        handleOpenAccount();
      })
      .catch(error => {
        console.error('Error:', error.response.data.data.message);
        let errorMessage = error.response.data.data.message;
        toast.error(errorMessage);
        setBtnDisabled(false);
      });
    }
  };

  const handleChangeNumberClick = () => {
    setChangeNumberClicked(true);
    setShowOtpField(false);
    setIsGetOtpButtonClicked(false);
    setCountdown(0);
  };

  const accountSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
  });

  const validateAccountFields = async (field, value) => {
    try {
      await schema.validateAt(field, { [field]: value });
      setErrors({ ...errors, [field]: '' });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setErrors({ ...errors, [field]: error.message });
      }
    }
  };

  const handleInputAccountChange = (field, value) => {
    if (field === 'name') {
      setName(value);
      validateField('name', value);
    } else if (field === 'email') {
      setEmail(value);
      validateField('email', value);
    }
  };

  const handleAccountButtonClick = async () => {
    try {
      await accountSchema.validate({ name, email }, { abortEarly: false });
      const requestData = {
        name: name,
        signupType: "REGULAR",
        mobile: number,
      };
      if (email) {
        requestData.email = email;
      }
      const response = await axios.post(
        `${API.BASE_URL}auth/user/create-account`,
        requestData
      );
      if (response.data.status === true) {
        console.log("Account created successfully:", response.data.data.userCouponData.coupon);
        toast.success("Account created successfully!", { autoClose: 3000,});
        handleCloseAccount();
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("couponPercentage", response.data.data.userCouponData.coupon.discountPercent);
        localStorage.setItem("couponCode", response.data.data.userCouponData.coupon.couponCode);
        localStorage.setItem('userNameHeader', response.data.data.createdUser.name);
        localStorage.setItem('userProfileImage', response.data.data.user.profileImage);
        localStorage.setItem('userDataLogin', JSON.stringify(response.data.data.user));
        window.location.reload();
      }
    } catch (validationError) {
      if (validationError instanceof Yup.ValidationError) {
        const newErrors = {};
        validationError.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setErrors(newErrors);
      } else {
        console.error("Error:", validationError.response.data.data.message);
        let errorMessage = validationError.response.data.data.message;
        toast.error(errorMessage, {
          autoClose: 3000,
        });
      }
    }
  };

  const getRandomRating = () => {
    return (Math.random() * (4.2 - 3.7) + 3.7).toFixed(1);
  };

  const [totalPrice, setTotalPrice] = useState(selectedProduct?.offerPrice || 0);
  const [totalMrpPrice, setTotalMrpPrice] = useState(selectedProduct?.mrp || 0);
  const [offerPercentage, setOfferPercentage] = useState(0);

  useEffect(() => {
    let calculateTotalPrice = () => {
      let price = selectedProduct?.offerPrice ?? 0;
      let mrp = selectedProduct?.mrp ?? 0;
      Object.values(selectedAddons).forEach(addon => {
        price += addon.offerPrice ?? 0;
        mrp += addon.mrp ?? 0;
      });
      setTotalPrice(price);
      setTotalMrpPrice(mrp);
    };
    calculateTotalPrice();
  }, [selectedAddons, selectedProduct]);

  useEffect(() => {
    if (totalMrpPrice > 0 && totalPrice < totalMrpPrice) {
      const discount = ((totalMrpPrice - totalPrice) / totalMrpPrice) * 100;
      setOfferPercentage(discount);
    } else {
      setOfferPercentage(0);
    }
  }, [totalPrice, totalMrpPrice]);

  const params = useParams();
  const referralId = params['referId'];

  useEffect(() => {
    if (referralId) {
      localStorage.setItem('referralId', referralId);
      console.log("Referral ID stored in localStorage:", referralId);
    }
  }, [referralId]); 

  return (
    <>
      {/* <ProductListingSkeleton /> */}
      <Hidden mdUp>
        <StoreHeader />
        <SearchBar />
        <ArrivingNowOrders />
        <Box className="imageCategories">
          <Box className="imageCategoryContainer">
            {categories.map((category, index) => (
              <Box className="img-grid" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Box key={category._id} onClick={() => handleCategoryClick(category._id, index)} className="imageBox">
                  <img className="image" src={category.imageURL} alt="product" />
                </Box>
                <Typography className="categoryName">{category.name}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <OrderAgain />
        <FavouriteProducts />
      </Hidden>
      <Hidden mdDown>
        <Box className="imageCategories">
          <Box className="imageCategoryContainer">
            {categories.map((category, index) => (
              <Box className="img-grid" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Box key={category._id} onClick={() => handleCategoryClick(category._id, index)} className="imageBox">
                  <img className="image" src={category.imageURL} alt="product" />
                </Box>
                <Typography className="categoryName">{category.name}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        {/* <Box className='filters'>
            <Box className="filterBy">
                <Typography sx={{ fontSize: '12px'}}>Filter By</Typography>
                <KeyboardArrowDown />
            </Box>
            <div className='filterItems'>
                <button className='allButton' style={{ backgroundColor: '#DFEEF4', color: '#6B6C6E' }}>
                    All
                </button>
                <button className='milkButton' style={{ backgroundColor: '#DFEEF4', color: '#6B6C6E' }}>
                    Milk
                </button>
                <button className='nomilkButton' style={{ backgroundColor: '#DFEEF4', color: '#6B6C6E' }}>
                    No Milk
                </button>
            </div>
        </Box> */}
      </Hidden>
      {categories.length <= 0 ? (
        <Typography>No Products Found</Typography>
      ) : (
      <>
        <Box className="products-listing">
          <Box className="category-list">
            {categories.map((category, index) => (
              <Box className="d-flex category-items-content" key={category._id}>
                <Box className={`category-item-highlight-line ${activeCategory === category._id || (index === 0 && !activeCategory) ? 'active' : ''}`}></Box>
                <Box
                  className={`category-item ${activeCategory === category._id || (index === 0 && !activeCategory) ? 'active' : ''}`}
                  onClick={() => handleCategoryClick(category._id, index)}
                >
                  {category.name}
                </Box>
              </Box>
            ))}
          </Box>
          <Box className="products-container">
            <Grid ref={containerRef} container>
              {categories.map((category, index) =>{
              let totalProductsRendered = 0;
              const totalProducts = category.products.length;
              let productsPerRow = 3;
                return (
                  // <Grid  ref={el => categoryRefs.current[index] = el} container key={category._id}>
                  <Grid container key={category._id}>
                    <Grid md={12}>
                      <img className="categoryTopImage" src={category?.bannerImageUrl} alt={category?.name} />
                    </Grid>
                    {category.products.map((product, productIndex) => {
                      const rowPosition = Math.floor(productIndex / productsPerRow);
                      const colPosition = productIndex % productsPerRow;
                      const isLastRow = (Math.ceil(totalProducts / productsPerRow) - 1) === rowPosition;
                      const isLastInRow = colPosition === (productsPerRow - 1) || productIndex === (totalProducts - 1);
                      const isFirstInRow = colPosition === 0;
                      return (
                        <>
                        <Grid item sm={12} md={4} key={product._id}>
                            <Box key={product?._id}
                              sx={{
                                ...(matchesMdUp ? { borderRight: isLastInRow ? 'none' : '1px dashed #D6D6D6', } : { borderRight: 'none', borderLeft: 'none', }),
                                borderBottom: isLastRow ? 'none' : '1px dashed #D6D6D6',
                                paddingBottom: (index === categories.length - 1 && totalProductsRendered === category.products.length) ? '20px' : '35px',
                                ...(matchesMdUp ? {} : {
                                  borderBottom: productIndex === category.products.length - 1 ? 'none' : '1px dashed #6a6a6a',
                                  paddingBottom: productIndex === category.products.length - 1 ? '20px' : '35px'
                                })
                              }}  
                              className={`product-item ${product.isExhausted ? 'exhausted' : ''}`}
                            >
                              <Grid container sx={{ alignItems: 'center' }}>
                                <Hidden smUp>
                                  <Grid item sm={3} md={3} className="imageBox" onClick={() => handleProductClick(product)}>
                                    <img className="image" src={product?.displayImageUrl} alt="Product" />
                                  </Grid>
                                </Hidden>
                                <Grid item sm={9} md={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                  <Grid container className="typeAndFavouriteBox" onClick={() => handleProductClick(product)} direction={'row'} justifyContent={'space-between'}>
                                    <Grid item sm={6} md={10} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <>
                                      {/* <img className='img-fluid' src={Images.veg} /> */}
                                      {product?.foodType === 'VEG/VEGAN' ? (
                                        <Stack direction={'row'}>
                                          <img className='veg-nonveg' src={Images.veg} alt='veg' />
                                          <img style={{ height: '17px', marginRight: '4px', marginLeft: '0px' }} className='veg-nonveg' src={Images.vegan} alt='vegan' />
                                        </Stack>
                                      ) : (
                                        <img className='veg-nonveg' src={product?.foodType === 'VEG' ? Images.veg : (product?.foodType === 'VEGAN' ? Images.vegan : Images.nonVeg)} alt={product?.foodType === 'VEG' ? 'veg' : (product?.foodType === 'VEGAN' ? 'vegan' : 'non-veg')} />
                                      )}
                                    </>
                                      <Typography className="productTag">{product?.productTag[0].tagName}</Typography>
                                    </Grid>
                                    <Grid item sm={6} md={2} sx={{ }}>
                                      {isProductInWishlist(product._id) ? (
                                        // <FavoriteRoundedIcon className="favouriteMarked" onClick={handleFavoriteClick} />
                                        <img src={Images.heartFilled} alt="heartIconFilled" />
                                      ) : (
                                        <img src={Images.heartIcon} alt="heartIconFilled" />
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Hidden smDown>
                                    <Box className="imageBox" onClick={() => handleProductClick(product)}>
                                      <img className="image" onClick={() => handleProductClick(product)} src={product?.displayImageUrl} alt="Product" onError={(e) => { e.target.onerror = null; e.target.src = Images.xpanseNavLogo; }} />
                                    </Box>
                                  </Hidden>
                                  <Stack onClick={() => handleProductClick(product)} direction={'column'}>
                                    <Typography className="productName">{product?.name}</Typography>
                                    <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                                      <Typography className="productPrice">₹{product?.offerPrice} <span className='productMrp'> ₹{product?.mrp}</span></Typography>
                                      <Typography className="productOff">{product?.offerPercentage?.toFixed(0)}% OFF</Typography>
                                    </Stack>
                                  </Stack>
                                  <Hidden smDown>
                                    <Typography onClick={() => handleProductClick(product)} className="description">{product?.description}</Typography>
                                  </Hidden>
                                  <Box className="ratingAndButton">
                                    {/* <Typography className="productRating"><StarRoundedIcon className="star"/>{product?.productRatings?.averageProductRatings.toFixed(1)} <span className="ratingNos">({product?.productRatings?.totalUsersCount})</span></Typography> */}
                                    <Typography className="productRating"><StarRoundedIcon className="star"/>{product?.productRatings?.averageProductRatings ? product.productRatings.averageProductRatings.toFixed(1) : 4.2}</Typography>
                                    {getProductQuantityInCart(product._id) > 0 ? (
                                      <Box className="addedBtn" onClick={() => handleProductClick(product)}>
                                        {getProductQuantityInCart(product._id)} Added
                                      </Box>
                                    ) : (
                                      <Box className={product?.isExhausted ? "exhaustedBtn" : "notAddedBtn"} onClick={!product?.isExhausted ? () => handleProductClick(product) : null}>
                                        {product?.isExhausted ? "Out Of Stock" : "ADD"}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                        </Grid>
                        </>);
                      })}
                  </Grid>
                )})}
            </Grid>
          </Box>
          <Drawer
            open={!!selectedProduct}
            onClose={handleCloseModal}
            anchor="bottom"
          >
            <Box className="productDrawer">
              <IconButton 
                onClick={handleCloseModal}
                sx={{ position: 'absolute', top: -20, right: 8, zIndex: 1, backgroundColor: '#fff', height: '16px', width: '16px' }}
              >
                <CloseOutlined sx={{ fontSize: '12px' }} />
              </IconButton>
              <Grid container>
                <Grid item xs={5} sx={{ justifyContent: 'center'}}>
                  <Box className="imageBox">
                    <img className="image" src={selectedProduct?.displayImageUrl} alt="Product" />
                  </Box>
                </Grid>
                <Grid item xs={7} sx={{ paddingLeft: '2%'}}>
                  <Box className="drawerVegAndFavourite">
                    <Box sx={{ display: 'flex' }}>
                      <Typography className="productRating" sx={{ textAlign: 'right'}}><StarRoundedIcon className="star"/>{selectedProduct?.productRatings?.averageProductRatings ? selectedProduct.productRatings.averageProductRatings.toFixed(1) : 4.2}</Typography>
                      <>
                        {selectedProduct?.foodType === 'VEG/VEGAN' ? (
                          <Stack direction={'row'}>
                            <img className='vegNonveg' src={Images.veg} alt='veg' />
                            <img className='vegNonveg' src={Images.vegan} alt='vegan' />
                          </Stack>
                        ) : (
                          <img className='vegNonveg' src={selectedProduct?.foodType === 'VEG' ? Images.veg : (selectedProduct?.foodType === 'VEGAN' ? Images.vegan : Images.nonVeg)} alt={selectedProduct?.foodType === 'VEG' ? 'veg' : (selectedProduct?.foodType === 'VEGAN' ? 'vegan' : 'non-veg')} />
                        )}
                      </>
                    </Box>
                    {isFavorited ? ( <FavoriteRoundedIcon className="favouriteMarked" onClick={handleFavoriteClick} /> ) : ( <FavoriteBorderRoundedIcon className="favouriteMarked" onClick={handleFavoriteClick} /> )}
                  </Box>
                  <Box className="productDetails">
                    {/* <Typography className="productTag">{selectedProduct.productTag[0].tagName}</Typography> */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography className="productName" sx={{ textAlign: 'left'}}>{selectedProduct?.name}</Typography>
                    </Box>
                    <Typography className="productInfo" sx={{ paddingBottom: '0px' }}>{selectedProduct?.calories} cal {selectedProduct?.weight}<span style={{ textTransform: 'capitalize' }}>{selectedProduct?.weightUnit}</span> </Typography>
                    <Typography className="productInfo">{selectedProduct?.description}</Typography>
                  </Box> 
                </Grid>
              </Grid>
              {selectedProduct && (
                <>
                  {selectedProduct.hasAddon && selectedProduct.addons && selectedProduct.addons.length > 0 && (
                    <Box className="addonsBox">
                      {selectedProduct.addons.map((addon) => (
                        <Grid container key={addon._id} sx={{ borderBottom: '1px solid #B3B3B3', padding: '10px 0 7px 0' }}>
                          <Grid item xs={3}>
                            <Typography className="addonTitle">{addon.addonTitle}</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <Grid container justifyContent="flex-end">
                              {addon.addonValues.map((addonValue) => {
                                const isSelected = selectedAddons[addon._id]?._id === addonValue._id || (!selectedAddons[addon._id] && addonValue.isDefault);
                                const icon = addonValue.iconInfo ? <AddonIconComponent iconInfo={addonValue.iconInfo} selected={isSelected} /> : null;
                                return (
                                  <Grid item xs={4} key={addonValue._id} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Box 
                                      className="addonItem" 
                                      onClick={() => handleAddonSelect(addon._id, { ...addonValue, offerPrice: addonValue.offerPrice ?? 0, mrp: addonValue.mrp ?? 0 })}  
                                      sx={{ display: 'flex', flexDirection: 'column' }}
                                    >
                                      <Box sx={{ flex: '1 1 auto' }} />
                                        {icon ? (
                                          <Box className="addonIcon" sx={{ margin: 0, padding: 0 }}> {icon} </Box>
                                        ) : (
                                          <Box className={`addonSelect ${isSelected ? 'selected' : ''}`} sx={{ alignSelf: 'center' }}> {addonValue.value ?? 0} </Box>
                                        )}
                                        <Typography className="addonValue" sx={{ alignSelf: 'center' }}> +₹{addonValue.offerPrice ?? 0} </Typography>
                                        {/* <Typography className="addonOutOfStock">Out Of Stock</Typography> */}
                                      </Box>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Box>
                  )}
                </>
              )}
              <Box className="priceAndButton">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className="productPrice">₹{totalPrice} <span className='productMrp'> ₹{totalMrpPrice}</span></Typography>
                  <Typography className="productOff">{offerPercentage?.toFixed(0)}% OFF</Typography>
                </Box>
                <Box className="addRemoveItem">
                  <RemoveCircleIcon className="text" onClick={handleDecreaseQuantity} />
                  <Typography sx={{ color: '#000' }} className="text">{quantity}</Typography>
                  <AddCircleIcon className="text" onClick={handleIncreaseQuantity} />
                </Box>
              </Box>
              {addingToCart ? (
                <Box className="button"><CircularProgress size={18} sx={{ color: '#fff' }} /> Adding to cart</Box>
              ) : (
                <Box className={selectedProduct?.isExhausted ? "exhaustedButton" : "button"} onClick={!selectedProduct?.isExhausted ? () => handleAddToCart() : null}>{selectedProduct?.isExhausted ? "Out Of Stock" : "ADD"}</Box>
              )}
            </Box>
          </Drawer>
          <Dialog open={loginModalOpen} onClose={() => setLoginModalOpen(false)}>
            <Box p={3}>
              <Typography variant="h5">Login Required</Typography>
              <Typography>Please login to use this feature.</Typography>
              <Button onClick={() => setLoginModalOpen(false)}>Close</Button>
              <Hidden mdUp>
                <Button onClick={() => navigate('/mobileNumber')}>Login Now</Button>
              </Hidden>
            </Box>
          </Dialog>
        </Box>        
        {/* <Box className="product-listing-curve">
          <Box className="product-listing-curve-inside-box"></Box>
        </Box> */}
        <Box className="product-listing-line"></Box>
      </>
      )}
      <Modal
        open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        sx={{ border: '0'}}
      >
        <Box className="authenticationScreen" sx={{outline: 'none'}}>
          <Grid className='mainBox' item md={3} sx={{ padding: '10px', margin: '0px' }}>
            <Box sx={{ textAlign: 'center' }}>
              <img className='imageLogo' src={Images.xpanseSplashLogo} alt="" />
            </Box>
            { !isGetOtpButtonClicked && 
              <Typography className="cup-text" sx={{ fontWeight: '600', fontSize: '25px', margin: '21px 0 31px 0', lineHeight: '1.2' }}>
                Lets Get You a Cup
              </Typography>
            }
            { isGetOtpButtonClicked &&
              <Box className="step-away-text" sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <ArrowBack sx={{ fontSize: '25px', margin: '0 8px 0 0px' }} onClick={handleChangeNumberClick} />
                <Typography sx={{ fontWeight: '600', fontSize: '25px' }}>
                  Your cup is one step away
                </Typography>
              </Box>
            }
            <Box sx={{ textAlign: 'left' }}>
              <Box className="numberField">
                <TextField
                  fullWidth
                  id="number"
                  sx={{
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#1DA1F2',
                      },
                      '&:hover fieldset': {
                        borderColor: 'cornflowerblue',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1DA1F2',
                      },
                    },
                  }}
                  disabled={isGetOtpButtonClicked}
                  placeholder="10 digit mobile number"
                  variant="outlined"
                  value={number}
                  onChange={(e) => handleInputChange('number', e.target.value)}
                  onBlur={() => validateField('number', number)}
                  InputLabelProps={{ style: { color: '#2196F3' } }}
                  error={!!errors.number}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box sx={{ display: 'flex', borderRight: '1px solid #474747', paddingRight: '8px' }}>
                          <span>+91</span>
                        </Box>
                      </InputAdornment>
                    ),
                    style: { borderColor: '#2196F3' },
                  }}
                />
                {errors.number && (
                  <Typography className="errorText" color="error">
                    {errors.number}
                  </Typography>
                )}
              </Box>
              <Box>
                <TextField
                  fullWidth
                  id="otp"
                  className={`otpField ${showOtpField ? 'showOtpField' : ''}`}
                  sx={{
                    borderRadius: '8px',
                    marginTop: '10px',
                    backgroundColor: '#fff',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#1DA1F2',
                      },
                      '&:hover fieldset': {
                        borderColor: 'cornflowerblue',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1DA1F2',
                      },
                    },
                  }}
                  placeholder="Enter OTP"
                  variant="outlined"
                  value={otp}
                  onChange={(e) => handleInputChange('otp', e.target.value)}
                  onBlur={() => validateField('otp', otp)}
                  inputProps={{ style: { textAlign: 'center' } }}
                  InputLabelProps={{ style: { color: '#2196F3' } }}
                  error={!!errors.otp}
                />
                {errors.otp && (
                  <Typography className="errorText" color="error">
                    {errors.otp}
                  </Typography>
                )}
              </Box>
            </Box>
            <Grid container justifyContent={'center'} style={{ margin: '27px 0 0px 0' }}>
              <Grid className="buttonContainer" item xs={12} sm={12} md={12} style={{ top: showOtpField ? '0px' : '-60px' }}>
                <PrimaryButton
                    title={showOtpField ? 'Proceed' : 'Get OTP'}
                    style={{ borderRadius: '30px', fontSize: '58px' }}
                    onClick={() => {
                        if (!showOtpField) {
                            handleButtonClick();
                        } else {
                            handleOtpButtonClick();
                            console.log('Proceed clicked');
                        }
                    }}
                    spinner={true}
                    disabledTitle={'Please Wait'}
                    disabled={btnDisabled}
                />
              </Grid>
            </Grid>
            { isGetOtpButtonClicked &&
              <Typography  className="resendOtp-text" style={{ fontSize: '16px', textAlign: 'center', margin: '20px 0 10px 0',  height: showOtpField ? '50px' : '0px'  }}>
                {countdown <= 0 ? (
                    <span
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => {
                          handleResendOtp();
                          setCountdown(59);
                        }}
                    >
                        Resend OTP
                    </span>
                    ) : (
                    <span>
                        Resend OTP in
                    <span
                    style={{
                        textDecoration: 'underline',
                        color: '#fff',
                        cursor: 'pointer',
                        marginLeft: '5px',
                    }}
                    >
                        {countdown < 10 ? `00:0${countdown}` : `00:${countdown}`}
                    </span>
                    </span>
                )}
              </Typography>
            }
            { !isGetOtpButtonClicked &&
               <Typography className='termsConditions' style={{ color: '#000', fontSize: '13px', textAlign: 'center' }}>
                By clicking, I accept the <span onClick={() => window.open('/termsAndConditions', '_blank')} style={{ textDecoration: 'underline', color: '#fff', cursor: 'pointer' }}>terms of service</span> &{' '}
                <span  onClick={() => window.open('/privacyPolicy', '_blank')} style={{ textDecoration: 'underline', color: '#fff', cursor: 'pointer' }}>privacy policy</span>
              </Typography>
            }
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={openAccount}
        onClose={handleCloseAccount}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        sx={{ border: '0'}}
      >
        <Box className="accountDetails" sx={{ outline: 'none', textAlign: 'left', padding: '0px', margin: '0px' }}>
          <Box sx={{ textAlign: 'center' }}>
            <img className='imageLogo' src={Images.xpanseSplashLogo} alt="" />
          </Box>
          <Grid className="mainBox" item md={3} sx={{ padding: '10px', margin: '0px' }}>
            <Typography sx={{ fontWeight: '800', fontSize: '25px', margin: '7px 0 11px 0' }}>
              Help us get to know you
            </Typography>
            <Box sx={{ textAlign: 'left' }}>
              <TextField
                fullWidth
                id="name"
                sx={{
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#1DA1F2',
                    },
                    '&:hover fieldset': {
                      borderColor: 'cornflowerblue',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1DA1F2',
                    },
                  },
                }}
                placeholder="Enter Name"
                variant="outlined"
                value={name}
                onChange={(e) => handleInputAccountChange('name', e.target.value)}
                onBlur={() => validateAccountFields('name', name)}
                InputLabelProps={{ 
                      shrink: true,
                  }}
                error={!!errors.name}
              />
              {errors.name && (
                <Typography className="errorText" color="error">
                  {errors.name}
                </Typography>
              )}
              <TextField
                fullWidth
                id="email"
                sx={{
                  marginTop: '20px',
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#1DA1F2',
                    },
                    '&:hover fieldset': {
                      borderColor: 'cornflowerblue',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1DA1F2',
                    },
                  },
                }}
                placeholder="Email"
                variant="outlined"
                value={email}
                onChange={(e) => handleInputAccountChange('email', e.target.value)}
                  InputLabelProps={{ 
                      shrink: true,
                  }}
                error={!!errors.email}
              />
              {errors.email && (
                <Typography className="errorText" color="error">
                  {errors.email}
                </Typography>
              )}
            </Box>
            <Grid container justifyContent={'center'} style={{ margin: '21px 0 21px 0' }}>
              <Grid item xs={12} sm={12} md={12}>
                <PrimaryButton
                  title={'Continue'}
                  onClick={handleAccountButtonClick}
                  spinner={true}
                  style={{ borderRadius: '30px', fontSize: '18px' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>      
  );
};

export default ProductsListing;
