const Images = {
    xpanseSplashLogo: require('../assets/images/xpanseSplashLogo.png'),
    xpanseNavLogo: require('../assets/images/navLogo.png'),
    xpanseBottomNavLogo: require('../assets/images/xpanceBottomNavLogo.png'),
    xpanseBottomNavLogoActive: require('../assets/images/xpanceBottomNavLogoActive.png'),
    xpanseCartActive: require('../assets/images/xpanseCartActive.png'),
    xpanseCartUnActive: require('../assets/images/xpanseCartUnActive.png'),
    xpanseOfferActive: require('../assets/images/xpanseOfferActive.png'),
    xpanseOfferUnActive: require('../assets/images/xpanseOfferUnActive.png'),
    xpanseMapMarker: require('../assets/images/xpanseMapMarker.png'),
    xpanseDeliveryPin: require('../assets/images/deliveryPin.png'),
    navProfile: require('../assets/images/navProfile.png'),
    maleAvatar: require('../assets/images/male.png'),
    femaleAvatar: require('../assets/images/female.png'),

    cloudR1: require('../assets/images/rc1.png'),
    cloudR2: require('../assets/images/rc2.png'),
    cloudL1: require('../assets/images/lc1.png'),
    cloudL2: require('../assets/images/lc2.png'),
    firstScreenDots: require('../assets/images/firstScreenDots.png'),
    secondScreenDots: require('../assets/images/secondScreenDots.png'),
    prevButton: require('../assets/images/prevButton.png'),
    nextButton: require('../assets/images/nextButton.png'),

    veg: require('../assets/images/veg.png'),
    nonVeg: require('../assets/images/nonVeg.png'),
    vegan: require('../assets/images/vegan.png'),
    successTick: require('../assets/images/successTick.png'),
    heartIcon: require('../assets/images/heartIcon.png'),
    shareIcon: require('../assets/images/shareIcon.png'),
    heartFilled: require('../assets/images/heartFilled.png'),
    cafeImage: require('../assets/images/cafe_img.png'),
    upcomingCafe: require('../assets/images/upcoming-cafe.png'),
    product1: require('../assets/images/product1.png'),
    coupon: require('../assets/images/coupon.png'),
    star: require('../assets/images/starOutlined.svg'),
    welcomeCoupon: require('../assets/images/welcomeCoupon.png'),
    couponScreenCoupon: require('../assets/images/couponScreen.png'),
    categoryHead: require('../assets/images/categoryHead.jpg'),
    profileHead: require('../assets/images/profileHead.png'),
    membershipCard: require('../assets/images/membershipCard.png'),
    xpanseCup: require('../assets/images/xpanseCup.png'),
    xpanseCupPointActive: require('../assets/images/xpanseCupPointActive.png'),
    xpanseCupPointUnActive: require('../assets/images/xpanseCupPointInactive.png'),

    gPayLogo: require('../assets/images/gPayLogo.png'),
    phonepeLogo: require('../assets/images/phonePeLogo.png'),
    upiLogo: require('../assets/images/upiLogo.png'),
    zomatoLogo: require('../assets/images/Zomato-logo.png'),

    orderDetailQrBox: require('../assets/images/orderDetailBox.png'),
    couponBorder: require('../assets/images/couponBorder.png'),
    couponBorderInverted: require('../assets/images/couponBorderInverted.png'),
    couponDiscountBg: require('../assets/images/couponDiscountBg.png'),
    couponDiscountBgInactive: require('../assets/images/couponDiscountBgInactive.png'),
    couponDiscountBgBirthday: require('../assets/images/couponDiscountBgBirthday.png'),
    circleDot: require('../assets/images/circleDot.png'),
    circleDotActive: require('../assets/images/circleDotActive.png'),

    video1: require('../assets/videos/coffe1.mp4'),
    video2: require('../assets/videos/coffe2.mp4'),

    adBanner: require('../assets/images/homeAd1.png'),
    homeCover: require('../assets/images/homeCover.jpg'),
    pickUpIcon: require('../assets/images/pickUpIcon.png'),
    deliveryOption: require('../assets/images/DeliveryOption.png'),
    pickupOption: require('../assets/images/pickupOption.png'),

    drinkSmall: require('../assets/images/drinkSmall.png'),
    drinkSmallSelected: require('../assets/images/drinkSmallSelected.png'),
    drinkMedium: require('../assets/images/drinkMedium.png'),
    drinkMediumSelected: require('../assets/images/drinkMediumSelected.png'),
    drinkLarge: require('../assets/images/drinkLarge.png'),
    drinkLargeSelected: require('../assets/images/drinkLargeSelected.png'),

    coupon1: require('../assets/images/coupon1.png'),
    coupon2: require('../assets/images/coupon2.png'),

    // Animations
    successGif: require('../assets/animations/successTick.json'),
    emptyCart: require('../assets/animations/emptyCart.json'),
    micAnimation: require('../assets/animations/micAnimation.json'),
}

export default Images;