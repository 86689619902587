import { Box } from '@mui/material';
import React from 'react'

const DeleteAccountScreen = () => {
  return (
    <Box>
      <h1>Delete Account Screen</h1>
    </Box>
  )
}

export default DeleteAccountScreen;
