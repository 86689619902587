import React, { useState, useEffect } from 'react';
import { Box, Hidden, useTheme, useMediaQuery, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import BottomNav from "./components/BottomNav";
import DesktopHeader from "./components/DesktopHeader";
import StickyHeader from "./components/StickyHeader";
import FavouriteProducts from "./components/FavouriteProducts";
import CartScreen from "./screens/App/CartScreen";
import AdvertiseScreen from "./screens/App/AdvertiseScreen";
import axios from 'axios';
import { API } from './utils/Api';
import ArrivingNowOrders from './components/ArrivingNowOrders';

const AppLayout = () => {
    const [showStickyHeader, setShowStickyHeader] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [transitionDuration, setTransitionDuration] = useState(500);
    const theme = useTheme();
    const matchesMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const [clickableAdvertise, setClickableAdvertise] = useState([]);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const handleScroll = () => {
            const shouldBeVisible = window.scrollY > 100;
            if (shouldBeVisible !== showStickyHeader) {
                setShowStickyHeader(shouldBeVisible);
                setTransitionDuration(shouldBeVisible ? 500 : 100);
                setIsVisible(shouldBeVisible);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showStickyHeader]);

    const outletStyle = {
        width: '70%',
        '@media (max-width: 768px)': { width: '100%' },
    };

    const getAdvertisments = async () => {
        try {
            const response = await axios.get(`${API.BASE_URL}advertisement/user`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setClickableAdvertise(response?.data?.data?.advertisements?.clickableAdvertisements);
        } catch (error) {
            console.log("Error in getting Ads", error.response);
        }
    };

    useEffect(() => {
        if (!token) {
            getAdvertisments();
        }
    }, [])

    return (
        <div>
            <Hidden smDown>
                <Box>
                    <DesktopHeader />
                    <StickyHeader isVisible={isVisible} transitionDuration={transitionDuration} />
                </Box>
            </Hidden>
            <Box component="main" sx={{ display: 'flex', flexGrow: 2 }}>
                <Box sx={outletStyle}>
                    <Outlet />
                </Box>
                <Hidden smDown>
                    <Box className="desktopThirdColumn">
                        <ArrivingNowOrders />
                        <CartScreen />
                         {!token &&
                            <Box className="advertiseScreen">
                                <div className='homeMain'>
                                    <Box className="homeContainer" sx={{ padding: '20px 15px 60px 10px' }}>
                                        {clickableAdvertise?.map((ad, index) => (
                                            <div className='adImageBox' key={index}>
                                                <img className="adImage" src={ad?.adImagePath} alt={`Advertisement ${index}`} />
                                            </div>
                                        ))}
                                    </Box>
                                </div>
                            </Box>
                        }
                        <FavouriteProducts />
                    </Box>
                </Hidden>
            </Box>
            <Hidden smUp>
                <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
                    <BottomNav />
                </Box>
            </Hidden>
        </div>
    );
};

export default AppLayout;
