import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress, Button, TextField, MenuItem, Tab, Tabs, Hidden, Drawer, Modal, useMediaQuery, Stack, IconButton } from "@mui/material";
import axios from "axios";
import { API } from "../../utils/Api";
import { useNavigate } from "react-router-dom";
import Images from "../../utils/Images";
import * as Yup from "yup";
import { Formik } from "formik";
import { styled } from '@mui/system';
import OrderHistoryScreen from "./OrderHistoryScreen";
import FavoriteIcon from '@mui/icons-material/Favorite';
import EditProfile from "./ProfileScreens/EditProfile";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import WishListScreen from "./WishListScreen";
import MembershipScreen from "./MembershipScreen";
import SavedAddressesScreen from "./ProfileScreens/SavedAddressesScreen";
import RewardsScreen from "./ProfileScreens/RewardsScreen";
import { RWebShare } from "react-web-share";
import OfferScreen from "./OfferScreen";
import PointsBar from "../../components/PointsBar";
import { CloseFullscreenOutlined, KeyboardArrowRight } from "@mui/icons-material";

gsap.registerPlugin(ScrollTrigger);

const Container = styled('div')({
  maxHeight: 'calc(100vh)',
  overflowY: 'auto',
});

const CustomTab = styled(Tab)({
  minWidth: 0,
  width: '33%',
});

const ProfileScreen = () => {
  const token = localStorage.getItem("token");
  const [value, setValue] = React.useState('one');
  const navigate = useNavigate();
  const [editProfileDrawer, setEditProfileDrawer] = useState(false);
  const [orderHistoryDrawer, setOrderHistoryDrawer] = useState(false);
  const [wishlistDrawer, setWishlistDrawer] = useState(false);
  const [savedAddressDrawer, setSavedAddressDrawer] = useState(false);
  const [rewardsDrawer, setRewardsDrawer] = useState(false);
  const [membershipDrawer, setMembershipDrawer] = useState(false);
  const [previousValue, setPreviousValue] = useState('one');
  const [openMembership, setopenMembership] = useState(false);
  const matchesMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const [isOpen, setIsOpen] = useState(false); 
  const [membershipPoints, setMembershipPoints] = useState([]);
  const [referredUsers, setReferredUsers] = useState([]);
  const [userData, setUserData] = useState(null);
  // const [userIdForReferral, setUserIdForReferral] = useState('');
  const userDataLogin = JSON.parse(localStorage.getItem('userDataLogin'));
  const userIdForReferral = userDataLogin?._id;
  // setUserIdForReferral(userData?._id);

  const menuItems = [
      { name: 'Privacy Policy', path: '/privacyPolicy' },
      { name: 'Terms and conditions', path: '/termsAndConditions' },
      // { name: `FAQ's`, path: '/frequentlyAskedQuestions' }
  ];

  useEffect(() => {
    if (!token) {
      navigate("/mobileNumber");
    } else {
      window.scrollTo(0, 0);
    }
  }, [token, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setUserData(JSON.parse(localStorage.getItem('userDataLogin')));
    getMembershipPoints();
    // getReferredUsers();
  }, []);

  const handleOpenMembership = ()=> {
    setopenMembership(true);
  }

  const handleCloseMembership = () => {
    setopenMembership(false);
  }

  const handleImageClick = () => {
    if (matchesMdUp) {
      // setopenMembership(true);
      setMembershipDrawer(true);
    } else {
      navigate('/membershipScreen');
    }
  };

  useEffect(() => {
    const direction = getAnimationDirection(previousValue, value);
    gsap.fromTo(".tabContent", { x: direction === "left" ? -100 : 100, opacity: 0 }, { duration: 0.8, x: 0, opacity: 1, ease: "power3.out" });
    setPreviousValue(value);
  }, [value]);

  const toggleEditProfileDrawer = (open) => (event) => {
    setEditProfileDrawer(open);
  };

  const toggleOrderHistoryDrawer = (open) => (event) => {
    setOrderHistoryDrawer(open);
  };

  const toggleWishlistDrawer = (open) => (event) => {
    setWishlistDrawer(open);
  };

  const toggleSavedAddressDrawer = (open) => (event) => {
    setSavedAddressDrawer(open);
  };

  const toggleRewardsDrawer = (open) => (event) => {
    setRewardsDrawer(open);
  };

  const toggleMembershipDrawer = (open) => (event) => {
    setMembershipDrawer(open);
  };

  const handleLogout = () => {
    localStorage.clear();
    if (window.innerWidth <= 768) {
      navigate("/mobileNumber");
    } else {
      navigate("/");
      window.location.reload();
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getAnimationDirection = (previous, current) => {
    const tabs = ['one', 'two', 'three', 'four'];
    const previousIndex = tabs.indexOf(previous);
    const currentIndex = tabs.indexOf(current);
    return previousIndex < currentIndex ? "left" : "right";
  };

  const getMembershipPoints = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}user/currentUser/wallet-and-level`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setMembershipPoints(response.data.data.dbResponse[0]);
      // console.log('Successfully fetched membership points data:', response.data.data.dbResponse);
    } catch (error) {
      console.error('Error while fetching membership points data:', error);
    }
  };

  const getReferredUsers = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}user/getReferredUsers`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      // setReferredUsers(response.data.data.dbResponse[0]);
      console.log('Successfully fetched Referred users data:', response.data);
    } catch (error) {
      console.error('Error while fetching Referred users data:', error);
    }
  };

  return (
    <>
    <Container className="profileScreen">
      {!token ? (
          <Hidden mdUp>
            <Box sx={{ minHeight: '100vh'}}>
              <Typography>Please log in to view your Profile details.</Typography>
            </Box>
          </Hidden>
        ) : (
        <>
          <Box className="membershipCardBox">
            <Box onClick={handleImageClick} className="memberLevelImage">
              <Stack direction={'row'} alignItems={'center'}>
                <img 
                  className="profileImage" 
                  alt="profileImg"
                  src={ userData?.profileImage ? userData?.profileImage : (userData?.gender === 'MALE' ? Images.maleAvatar : (userData?.gender === 'FEMALE' ? Images.femaleAvatar : Images.navProfile)) }
                  // src={ userData?.profileImage || Images.navProfile } 
                />
                <Box sx={{ padding: '8px 0 0 7px' }}>
                  <Typography variant="body1" sx={{ color: '#003C78', fontWeight: '500' }}>{userData?.name}</Typography>
                  <Typography variant="body2" sx={{ color: '#003C78', fontWeight: '300' }}>{membershipPoints?.currentLevel?.title}</Typography>
                  <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', color: '#1E70AC' }}>View all benefits <KeyboardArrowRight fontSize={'12px'} /> </Typography>
                </Box>
              </Stack>
              <Box className="pointsBox" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                <Stack direction={'row'} alignItems={'baseline'}>
                  <Stack direction={'row'} alignItems={'baseline'}>
                    <Typography className="points">{membershipPoints?.earnedPoints?.toFixed(0)}</Typography>
                    <img className="xpanseCup" alt="xpanseCup" src={Images.xpanseCup} />
                  </Stack>
                  <Typography sx={{ fontWeight: '700' }} className="rewardText">Reward Points</Typography>
                </Stack>
                <Box sx={{ textAlign: 'right', position: 'relative', top: '-22px' }}>
                  <Typography sx={{ fontSize: '9px' }}>Current month points</Typography>
                  <Typography sx={{ fontWeight: '500' }} className="rewardText">{membershipPoints?.monthlyPoints?.toFixed(0)}</Typography>
                </Box>
              </Box>
            </Box>
            <PointsBar />
          </Box>
          <Box className="tabBody">
            <Hidden mdDown>
              <ul className="listSection">
                <li className="listItems" onClick={toggleEditProfileDrawer(true)}>Edit Profile<span className="greaterThanicon">&gt;</span> </li>
                <li className="listItems" onClick={toggleOrderHistoryDrawer(true)}>Order History<span className="greaterThanicon">&gt;</span> </li>
                <li className="listItems" onClick={toggleRewardsDrawer(true)}>Offers<span className="greaterThanicon">&gt;</span> </li>
                <li>
                  <RWebShare
                    data={{
                      text: "A place for all the coffeeheads!!!",
                      url: `https://xpanse.namastechai.in/${userIdForReferral}`,
                      title: "Xpanse",
                    }}
                    onClick={() => console.log("shared successfully!")}
                  >
                    <div className="listItems">Refer<span className="greaterThanicon">&gt;</span> </div>
                  </RWebShare>
                </li>
                <li className="listItems" onClick={toggleSavedAddressDrawer(true)}>Saved Address<span className="greaterThanicon">&gt;</span></li>                  
                <li className="listItems" onClick={toggleWishlistDrawer(true)}>Wishlist<span className="greaterThanicon">&gt;</span></li>
                {/* <li className="listItems" onClick={() => navigate('/frequentlyAskedQuestions')}>FAQs<span className="greaterThanicon">&gt;</span></li> */}
                <li className="listItems" onClick={() => setIsOpen(!isOpen)}>App Policies<span className="greaterThanicon">&gt;</span></li>
                {/* <Typography className="logoutSection"><p className="logout" onClick={handleLogout}>Log out</p></Typography> */}
                {isOpen && (
                  <ul>
                    {menuItems.map(item => (
                      <li key={item.name} onClick={() => window.open(item.path, '_blank')} style={{ padding: '4px 0 4px 0', cursor: 'pointer' }}>
                        {item.name}
                      </li>
                    ))}
                  </ul>
                )}
                <li className="listItems" onClick={handleLogout}>Logout<span className="greaterThanicon">&gt;</span></li>
              </ul>
              <div className="versionSection">
                {/* <Typography>Xpanse - v 1.001</Typography> */}
                <Typography className="madeInSection">Proudly Made in Goa <FavoriteIcon sx={{ color: '#71BAE6'}} /> </Typography>
              </div>
              {/* <div>
                <Typography className="logoutSection"><p className="logout" onClick={handleLogout}>Log out</p></Typography>
              </div> */}
            </Hidden>
            <Hidden mdUp>
              <div className="stickyTabsContainer">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="secondary tabs example"
                >
                  <CustomTab value="one" label="Profile" className="tabTitle" />
                  <CustomTab value="two" label="Order History" className="tabTitle" />
                  <CustomTab value="three" label="Referrals" className="tabTitle" />
                </Tabs>
              </div>
              {value === 'one' && (
                <Box className="tabContent">
                  <ul className="listSection">
                    <li className="listItems" onClick={() => navigate('/editProfileScreen')}>Edit Profile<span className="greaterThanicon">&gt;</span> </li>
                    <li className="listItems" onClick={() => navigate('/wishlistScreen')}>Wishlist<span className="greaterThanicon">&gt;</span></li>
                    <li className="listItems" onClick={() => navigate('/savedAddressesScreen')}>Saved Address<span className="greaterThanicon">&gt;</span></li>                  
                    <li className="listItems" onClick={() => setIsOpen(!isOpen)}>App Policies<span className="greaterThanicon">&gt;</span></li>
                    {isOpen && (
                      <ul>
                        {menuItems.map(item => (
                          <li key={item.name} onClick={() => navigate(item.path)} style={{ padding: '4px 0 4px 0', cursor: 'pointer' }}>
                            {item.name}
                          </li>
                        ))}
                      </ul>
                    )}
                    <li className="listItems"  onClick={handleLogout}>Logout<span className="greaterThanicon">&gt;</span></li>                  
                  </ul>
                  <div className="versionSection">
                    {/* <Typography>Xpanse - v 1.001</Typography> */}
                    <Typography className="madeInSection">Proudly Made in Goa <FavoriteIcon sx={{ color: '#71BAE6'}} /> </Typography>
                  </div>
                  {/* <div>
                    <Typography className="logoutSection"><p className="logout" onClick={handleLogout}>Log out</p></Typography>
                  </div> */}
                </Box>
              )}
              {value === 'two' && (
                <Box className="tabContent">
                  <OrderHistoryScreen />
                </Box>
              )}
              {value === 'three' && (
                <Box className="tabContent">
                  <ul className="listSection">
                  <RWebShare
                    data={{
                      text: "A place for all the coffeeheads!!!",
                      url: `https://xpanse.namastechai.in/${userIdForReferral}`,
                      title: "Xpanse",
                    }}
                    onClick={() => console.log("shared successfully!")}
                    >
                    <Box className="shareButton">
                      <Typography>Invite your friends to join the <br />Coffee Revolution</Typography>
                      <img className="shareIcon" src={Images.shareIcon} alt="share-icon" />
                    </Box>
                  </RWebShare>
                  </ul>
                </Box>
              )}
            </Hidden>
          </Box>
        </>
      )}
    </Container>
    <Drawer
      anchor="right"
      open={editProfileDrawer}
      onClose={toggleEditProfileDrawer(false)}
      classes={{ paper: "drawerPaperForEditProfile" }}
    >
      <Box className="editProfile" onClick={(event) => event.stopPropagation()}   sx={{ }}>
        <EditProfile />
      </Box>
    </Drawer>
    <Drawer
      anchor="right"
      open={orderHistoryDrawer}
      onClose={toggleOrderHistoryDrawer(false)}
      classes={{ paper: "drawerPaperForEditProfile" }}
    >
      <Box className="orderHistory" onClick={(event) => event.stopPropagation()}   sx={{ }}>
        <OrderHistoryScreen />
      </Box>
    </Drawer>
    <Drawer
      anchor="right"
      open={wishlistDrawer}
      onClose={toggleWishlistDrawer(false)}
      classes={{ paper: "drawerPaperForWishlist" }}
    >
      <Box className="wishlist" onClick={(event) => event.stopPropagation()}   sx={{ }}>
        <WishListScreen />
      </Box>
    </Drawer>
    <Drawer
      anchor="right"
      open={savedAddressDrawer}
      onClose={toggleSavedAddressDrawer(false)}
      classes={{ paper: "drawerPaperForSavedAddress" }}
    >
      <Box onClick={(event) => event.stopPropagation()}   sx={{ overflowY: 'auto' }}>
        <SavedAddressesScreen />
      </Box>
    </Drawer>
    <Drawer
      anchor="right"
      open={membershipDrawer  }
      onClose={toggleMembershipDrawer(false)}
    >
      <Box className="savedAddressDrawer" onClick={(event) => event.stopPropagation()}>
        {/* <IconButton onClick={toggleMembershipDrawer(false)} sx={{ position: 'absolute', left: '-10%', backgroundColor: '#fff' }}>
          <CloseFullscreenOutlined />
        </IconButton> */}
        <MembershipScreen />
      </Box>
    </Drawer>
    <Drawer
      anchor="right"
      open={rewardsDrawer}
      onClose={toggleRewardsDrawer(false)}
      classes={{ paper: "drawerPaperForSavedAddress" }}
    >
      <Box className="savedAddressDrawer" onClick={(event) => event.stopPropagation()}   sx={{ }}>
        <OfferScreen />
      </Box>
    </Drawer>
    <Modal
      open={openMembership}
      onClose={handleCloseMembership}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      sx={{ border: '0', borderRadius: '12px' }}
    >
      <Box className="membershipModal" sx={{ outline: 'none', borderRadius: '12px', padding: '0px', margin: '0px' }}>
        <MembershipScreen />
      </Box>
    </Modal>
    </>
  );
};

export default ProfileScreen;
